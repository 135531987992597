// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-ga-setup-js": () => import("./../../../src/pages/blog/ga-setup.js" /* webpackChunkName: "component---src-pages-blog-ga-setup-js" */),
  "component---src-pages-blog-off-the-shelf-shopify-themes-the-pros-and-cons-js": () => import("./../../../src/pages/blog/off-the-shelf-shopify-themes-the-pros-and-cons.js" /* webpackChunkName: "component---src-pages-blog-off-the-shelf-shopify-themes-the-pros-and-cons-js" */),
  "component---src-pages-case-studies-coat-paints-js": () => import("./../../../src/pages/case-studies/coat-paints.js" /* webpackChunkName: "component---src-pages-case-studies-coat-paints-js" */),
  "component---src-pages-case-studies-seep-js": () => import("./../../../src/pages/case-studies/seep.js" /* webpackChunkName: "component---src-pages-case-studies-seep-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-meet-the-team-js": () => import("./../../../src/pages/meet-the-team.js" /* webpackChunkName: "component---src-pages-meet-the-team-js" */)
}

